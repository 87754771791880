import React, { useEffect, useState, useCallback } from "react";
import AccordionMenuComponent from "../../components/AccordionMenuComponent/AccordionMenuComponent";
import BasicInfoComponent from "../../components/BasicInfoComponent/BasicInfoComponent";
import CommentComponent from "../../components/CommentComponent/CommentComponent";
import GreetingComponent from "../../components/GreetingComponent/GreetingComponent";
import MainPicComponent from "../../components/MainPicComponent/MainPicComponent";
import MockupComponent from "../../components/MockupComponent/MockupComponent";
import NoticeComponent from "../../components/NoticeComponent/NoticeComponent";
import VideoComponent from "../../components/VideoComponent/VideoComponent";
import FlowerPresentComponent from "../../components/FlowerPresentComponent/FlowerPresentComponent";
import SurveyComponent from "../../components/SurveyComponent/SurveyComponent";
import GalleryComponent from "../../components/GalleryComponent/GalleryComponent";
import AccountNumberComponent from "../../components/AccountNumberComponent/AccountNumberComponent";
import WeddingLocationComponent from "../../components/WeddingLocationComponent/WeddingLocationComponent";
import WeddingRootComponent from "../../components/WeddingRootComponent/WeddingRootComponent";
import TemplateComponent from "../../components/TemplateComponent/TemplateComponent";
import { dbService } from "../../fbase";
import LayoutComponent from "../../components/LayoutComponent/LayoutComponent";
import { debounce } from "lodash";
import ThanksComponent from "../../components/ThanksComponent/ThanksComponent";
import BackgroundMusicComponent from "../../components/BackgroundMusicComponent/BackgroundMusicComponent";
import EtcComponent from "../../components/EtcComponent/EtcComponent";
import ThumbnailComponent from "../../components/ThumbnailComponent/ThumbnailComponent";
import Header from "../../components/NewComponents/Header/Header";
import { useParams, useHistory } from "react-router-dom";
import { ThreeDots } from "react-loader-spinner";
import localStorage from "mobx-localstorage";
import TutorialComponent from "../../components/TutorialComponent/TutorialComponent";
import HeaderVer2 from "../../components/NewComponents/HeaderVer2/HeaderVer2";
import AddressComponent from "../../components/AddressComponent/AddressComponent";

const productionPage = ({
    letter,
    getData,
    letterId,
    getSwitchData,
    pageNum,
}) => {
    return (
        <div
            style={{
                width: pageNum === 0 ? "auto" : 0,
                height: pageNum === 0 ? "auto" : 0,
                overflow: "hidden",
            }}
        >
            <AccordionMenuComponent
                title={"템플릿"}
                component={
                    <TemplateComponent
                        state={letter}
                        getData={getData}
                        letterId={letterId}
                    />
                }
                isRequire={true}
            />

            <AccordionMenuComponent
                title={"메인사진 및 예식일자"}
                component={
                    <MainPicComponent
                        state={letter}
                        getData={getData}
                        letterId={letterId}
                    />
                }
                isRequire={true}
            />

            <AccordionMenuComponent
                title={"신랑측 정보"}
                component={
                    <BasicInfoComponent
                        parameter="husband"
                        getData={getData}
                        state={letter}
                        letterId={letterId}
                    />
                }
                isRequire={true}
            />

            <AccordionMenuComponent
                title={"신부측 정보"}
                component={
                    <BasicInfoComponent
                        parameter="wife"
                        getData={getData}
                        state={letter}
                        letterId={letterId}
                    />
                }
                isRequire={true}
            />

            <AccordionMenuComponent
                title={"인삿말"}
                component={
                    <GreetingComponent
                        getData={getData}
                        state={letter}
                        letterId={letterId}
                    />
                }
                isRequire={true}
            />

            <AccordionMenuComponent
                title={"예식장"}
                component={
                    <WeddingLocationComponent
                        getData={getData}
                        state={letter}
                        letterId={letterId}
                    />
                }
                isRequire={false}
                isActivity={letter.isWeddingLocation === true ? true : false}
                parameter={"isWeddingLocation"}
                getSwitchData={getSwitchData}
                _checked={letter.isWeddingLocation}
            />

            <AccordionMenuComponent
                title={"오시는 길"}
                component={
                    <WeddingRootComponent
                        getData={getData}
                        state={letter}
                        letterId={letterId}
                    />
                }
                isRequire={false}
                parameter={"isWeddingRoot"}
                getSwitchData={getSwitchData}
                _checked={letter.isWeddingRoot}
            />

            <AccordionMenuComponent
                title={"공지사항"}
                component={
                    <NoticeComponent
                        getData={getData}
                        state={letter}
                        letterId={letterId}
                    />
                }
                isRequire={false}
                parameter={"isNotice"}
                getSwitchData={getSwitchData}
                _checked={letter.isNotice}
            />

            <AccordionMenuComponent
                title={"비디오"}
                component={
                    <VideoComponent
                        getData={getData}
                        state={letter}
                        letterId={letterId}
                    />
                }
                isRequire={false}
                parameter={"isVideo"}
                getSwitchData={getSwitchData}
                _checked={letter.isVideo}
            />

            <AccordionMenuComponent
                title={"갤러리"}
                component={
                    <GalleryComponent
                        getData={getData}
                        state={letter}
                        letterId={letterId}
                    />
                }
                isRequire={false}
                parameter={"isGallery"}
                getSwitchData={getSwitchData}
                _checked={letter.isGallery}
            />

            <AccordionMenuComponent
                title={"계좌번호"}
                component={
                    <AccountNumberComponent
                        getData={getData}
                        state={letter}
                        letterId={letterId}
                    />
                }
                isRequire={false}
                parameter={"isAccount"}
                getSwitchData={getSwitchData}
                _checked={letter.isAccount}
            />

            <AccordionMenuComponent
                title={"방명록"}
                component={
                    <CommentComponent
                        getData={getData}
                        state={letter}
                        letterId={letterId}
                    />
                }
                isRequire={false}
                parameter={"isComment"}
                getSwitchData={getSwitchData}
                _checked={letter.isComment}
            />

            <AccordionMenuComponent
                title={"참석자 응답조사"}
                component={
                    <SurveyComponent
                        getData={getData}
                        state={letter}
                        letterId={letterId}
                    />
                }
                isRequire={false}
                parameter={"isSurvey"}
                getSwitchData={getSwitchData}
                _checked={letter.isSurvey}
            />

            <AccordionMenuComponent
                title={"축하화환 보내기"}
                component={
                    <FlowerPresentComponent
                        getData={getData}
                        state={letter}
                        letterId={letterId}
                    />
                }
                isRequire={false}
                parameter={"isFlower"}
                getSwitchData={getSwitchData}
                _checked={letter.isFlower}
            />

            {/* <AccordionMenuComponent
                title={"영상 추가"}
                component={
                    <VideoComponent
                        getData={getData}
                        state={letter}
                        letterId={letterId}
                    />
                }
                isRequire={false}
                parameter={"isVideo"}
                getSwitchData={getSwitchData}
                _checked={letter.isVideo}
            /> */}

            <AccordionMenuComponent
                title={"아웃트로"}
                component={
                    <ThanksComponent
                        getData={getData}
                        state={letter}
                        letterId={letterId}
                    />
                }
                isRequire={false}
                parameter={"isThanks"}
                getSwitchData={getSwitchData}
                _checked={letter.isThanks}
            />
        </div>
    );
};

const functionPage = ({ getData, letter, letterId, pageNum }) => {
    return (
        <div
            style={{
                width: pageNum === 1 ? "auto" : 0,
                height: pageNum === 1 ? "auto" : 0,
                overflow: "hidden",
            }}
        >
            <AccordionMenuComponent
                title={"배경음악"}
                component={
                    <BackgroundMusicComponent
                        getData={getData}
                        state={letter}
                        letterId={letterId}
                    />
                }
                isRequire={true}
            />

            <AccordionMenuComponent
                title={"레이아웃 순서 변경"}
                component={
                    <LayoutComponent
                        getData={getData}
                        state={
                            letter.layoutArr
                                ? letter
                                : {
                                      layoutArr: [
                                          { key: 0, label: "인삿말" },
                                          { key: 1, label: "예식일자" },
                                          { key: 8, label: "비디오" },
                                          { key: 2, label: "갤러리" },
                                          { key: 3, label: "공지사항" },
                                          { key: 4, label: "오시는길" },
                                          { key: 5, label: "참석여부" },
                                          { key: 6, label: "방명록" },
                                          {
                                              key: 7,
                                              label: "마음전하실 곳(화환)",
                                          },
                                      ],
                                  }
                        }
                        letterId={letterId}
                    />
                }
                isRequire={true}
            />

            <AccordionMenuComponent
                title={"부가적인 기능"}
                component={
                    <EtcComponent
                        getData={getData}
                        state={letter}
                        letterId={letterId}
                    />
                }
                isRequire={true}
            />

            <AccordionMenuComponent
                title={"썸네일"}
                component={
                    <ThumbnailComponent
                        getData={getData}
                        state={letter}
                        letterId={letterId}
                    />
                }
                isRequire={true}
            />
        </div>
    );
};

export default function ProductionScreen({ props }) {
    const [pageNum, setPageNum] = useState(0);
    const [letter, setLetter] = useState({});
    const [isSaved, setIsSaved] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [userId, setUserId] = useState("");
    const [letterId, setLetterId] = useState(useParams().letterId);

    const history = useHistory();

    const getData = (props) => {
        // console.log(props);

        if (props.isSaved) {
            setIsSaved(true);

            setTimeout(() => {
                setIsSaved(false);
            }, 1500);
        }
    };

    const getSwitchData = ({ parameter, check }) => {
        if (parameter === "isWeddingLocation") {
            debounceGetText({ parameter, check });
        } else if (parameter === "isWeddingRoot") {
            debounceGetText({ parameter, check });
        } else if (parameter === "isNotice") {
            debounceGetText({ parameter, check });
        } else if (parameter === "isGallery") {
            debounceGetText({ parameter, check });
        } else if (parameter === "isComment") {
            debounceGetText({ parameter, check });
        } else if (parameter === "isSurvey") {
            debounceGetText({ parameter, check });
        } else if (parameter === "isFlower") {
            debounceGetText({ parameter, check });
        } else if (parameter === "isVideo") {
            debounceGetText({ parameter, check });
        } else if (parameter === "isThanks") {
            debounceGetText({ parameter, check });
        } else if (parameter === "isAccount") {
            debounceGetText({ parameter, check });
        }
    };

    const onSubmit = async ({
        isWeddingLocation,
        isWeddingRoot,
        isNotice,
        isAccount,
        isGallery,
        isComment,
        isSurvey,
        isFlower,
        isVideo,
        isThanks,
        versionUpdate,
    }) => {
        let content = {};

        if (isWeddingLocation !== undefined) {
            content = {
                isWeddingLocation: isWeddingLocation,
            };
        } else if (isWeddingRoot !== undefined) {
            content = {
                isWeddingRoot,
            };
        } else if (isNotice !== undefined) {
            content = {
                isNotice,
            };
        } else if (isAccount !== undefined) {
            content = {
                isAccount,
            };
        } else if (isGallery !== undefined) {
            content = {
                isGallery,
            };
        } else if (isComment !== undefined) {
            content = {
                isComment,
            };
        } else if (isSurvey !== undefined) {
            content = {
                isSurvey,
            };
        } else if (isFlower !== undefined) {
            content = {
                isFlower,
            };
        } else if (isVideo !== undefined) {
            content = {
                isVideo,
            };
        } else if (isThanks !== undefined) {
            content = {
                isThanks,
            };
        }

        await dbService
            .collection("newletters")
            .doc(letterId)
            .update(content)
            .then((res) => {
                pushData();
            });
    };

    const debounceGetText = useCallback(
        debounce(({ parameter, text, check }) => {
            if (parameter === "isWeddingLocation") {
                onSubmit({ isWeddingLocation: check });
            } else if (parameter === "isWeddingRoot") {
                onSubmit({ isWeddingRoot: check });
            } else if (parameter === "isNotice") {
                onSubmit({ isNotice: check });
            } else if (parameter === "isAccount") {
                onSubmit({ isAccount: check });
            } else if (parameter === "isGallery") {
                onSubmit({ isGallery: check });
            } else if (parameter === "isComment") {
                onSubmit({ isComment: check });
            } else if (parameter === "isSurvey") {
                onSubmit({ isSurvey: check });
            } else if (parameter === "isFlower") {
                onSubmit({ isFlower: check });
            } else if (parameter === "isVideo") {
                onSubmit({ isVideo: check });
            } else if (parameter === "isThanks") {
                onSubmit({ isThanks: check });
            }
        }, 500),
        []
    );

    const pushData = () => {
        getData({ isSaved: true });
    };

    const _view = () => {
        let _return;
        switch (pageNum) {
            case 0:
                _return = productionPage({
                    letter: letter,
                    getData: getData,
                    letterId: letterId,
                    getSwitchData: getSwitchData,
                });
                break;

            case 1:
                _return = functionPage({
                    getData: getData,
                    letter: letter,
                    letterId: letterId,
                });
                break;

            default:
                break;
        }

        return _return;
    };

    const getLetter = (_letterId, _getUserId) => {
        var letterRef = dbService.collection("newletters");

        letterRef
            .doc(_letterId)
            .get()
            .then(async (res) => {
                if (!res.empty) {
                    if (
                        _getUserId === res.data().userId ||
                        "51543483" == _getUserId
                    ) {
                        const letterData = res.data();

                        // console.log(letterData);
                        setLetter(letterData);

                        setTimeout(() => {
                            setIsLoading(true);
                        }, 1000);
                    } else {
                        alert("비정상적인 접근입니다.");
                        window.location.href = "/#/newlogin";
                    }
                } else {
                    alert("비정상적인 접근입니다.");
                    window.location.href = "/#/newlogin";
                }
            });
    };

    const getUserId = new Promise((resolve, reject) => {
        let _getUserId = "";
        if (localStorage.getItem("userId")) {
            _getUserId = localStorage.getItem("userId");
            // setUserId(_getUserId);
        } else {
            // window.location.href = "/#/login";
            // await localStorage.setItem("userId", "51543483");
            reject(new Error("please login"));
        }

        resolve(_getUserId);
    });

    const createLetter = async ({ _userId }) => {
        const content = await {
            createdAt: Date.now(),
            userId: _userId,
        };

        await dbService
            .collection("newletters")
            .add(content)
            .then((res) => {
                // console.log(res.id);

                //letterId 가져오기 후 저장
                setLetterId(res.id);

                history.push(`/product/${res.id}`);
                getLetter(res.id);
            });
    };

    useEffect(() => {
        getUserId
            .then((_userId) => {
                setUserId(_userId);
                if (letterId === undefined) {
                    // console.log(history);
                    if (history.action === "POP") {
                        history.push("/");
                        console.log("POP");
                    } else {
                        // letter 생성
                        createLetter({ _userId: _userId });
                    }
                } else {
                    getLetter(letterId, _userId);
                }
            })
            .catch((error) => console.log(error));
    }, []);

    return (
        <div
            style={{
                backgroundColor: "#f4f4f4",
                minHeight: "100vh",
                // overflow: "hidden",
                position: "relative",
            }}
        >
            {isLoading ? (
                <>
                    <TutorialComponent state={letter} letterId={letterId} />
                    <HeaderVer2 userId={userId} />

                    <div class="prod-container" style={{}}>
                        <div
                            style={{
                                paddingTop: 100,
                                display: "flex",
                                // height: "100vh",
                                width: "100%",
                                maxWidth: 1000,
                            }}
                        >
                            <MockupComponent
                                _isSaved={isSaved}
                                letterId={letterId}
                            />

                            <div class="prod-item-container">
                                <div
                                    style={{
                                        maxWidth: "500px",
                                        // minHeight: "2500px",
                                        paddingBottom: "4rem",
                                        margin: "0 auto",
                                    }}
                                >
                                    <AddressComponent
                                        letterId={letterId}
                                        state={letter}
                                    />

                                    <div
                                        style={{
                                            display: "flex",
                                            marginTop: "1rem",
                                        }}
                                    >
                                        <div
                                            onClick={() => {
                                                setPageNum(0);
                                            }}
                                            className="prod-button"
                                            style={{
                                                flex: 1,
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                backgroundColor:
                                                    pageNum == 0
                                                        ? "#182e0c"
                                                        : "#fff",
                                                color:
                                                    pageNum == 0
                                                        ? "#fff"
                                                        : "#333",
                                            }}
                                        >
                                            페이지
                                        </div>
                                        <div
                                            onClick={() => setPageNum(1)}
                                            className="prod-button"
                                            style={{
                                                flex: 1,
                                                marginLeft: 8,
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                backgroundColor:
                                                    pageNum == 1
                                                        ? "#182e0c"
                                                        : "#fff",
                                                color:
                                                    pageNum == 1
                                                        ? "#fff"
                                                        : "#333",
                                            }}
                                        >
                                            부가적인 기능
                                        </div>
                                    </div>
                                    <div class="prod-block-container">
                                        {productionPage({
                                            letter: letter,
                                            getData: getData,
                                            letterId: letterId,
                                            getSwitchData: getSwitchData,
                                            pageNum: pageNum,
                                        })}

                                        {functionPage({
                                            getData: getData,
                                            letter: letter,
                                            letterId: letterId,
                                            pageNum: pageNum,
                                        })}
                                    </div>
                                </div>

                                <MockupComponent
                                    _isSaved={isSaved}
                                    bottomMockup={true}
                                    letterId={letterId}
                                />
                            </div>
                        </div>
                    </div>
                </>
            ) : (
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column",
                        height: "100vh",
                        width: "100vw",
                    }}
                >
                    <ThreeDots
                        height="60"
                        width="60"
                        radius="9"
                        color="#182e0c"
                        ariaLabel="three-dots-loading"
                        wrapperStyle={{}}
                        wrapperClassName=""
                        visible={true}
                    />
                </div>
            )}
        </div>
    );
}
