import React, { useState, useCallback } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import SubTitleComponent from "../SubTitleComponent/SubTitleComponent";
import TextInputComponent from "../TextInputComponent/TextInputComponent";
import { debounce } from "lodash";
import { dbService } from "../../fbase";
import { MdUpload } from "react-icons/md";
import Compressor from "compressorjs";
import S3 from "react-aws-s3";
import { v4 as uuidv4 } from "uuid";
import CloseIcon from "@material-ui/icons/Close";
import CreateIcon from "@material-ui/icons/Create";

const STORAGE_URL = "https://storage.cryucard.com";

export default function NoticeComponent({ state, getData, letterId }) {
  // s3설정
  const config = {
    bucketName: "new-cryucard",
    dirName: `image/${state.weddingDate}`,
    region: "ap-northeast-2",
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
  };

  const ReactS3Client = new S3(config);
  // s3설정

  const [noticeMenu, setNoticeMenu] = useState(1);

  const [noticeImg, setNoticeImg] = useState(
    state.noticeImg ? state.noticeImg : ""
  );

  const [noticeImg2, setNoticeImg2] = useState(
    state.noticeImg2 ? state.noticeImg2 : ""
  );

  const [noticeImg3, setNoticeImg3] = useState(
    state.noticeImg3 ? state.noticeImg3 : ""
  );

  const [noticeTitle, setNoticeTitle] = useState(
    state.noticeTitle ? state.noticeTitle : ""
  );

  const [noticeTitle2, setNoticeTitle2] = useState(
    state.noticeTitle2 ? state.noticeTitle2 : ""
  );

  const [noticeTitle3, setNoticeTitle3] = useState(
    state.noticeTitle3 ? state.noticeTitle3 : ""
  );

  const [noticeContent, setNoticeContent] = useState(
    state.noticeContent ? state.noticeContent : ""
  );

  const [noticeContent2, setNoticeContent2] = useState(
    state.noticeContent2 ? state.noticeContent2 : ""
  );

  const [noticeContent3, setNoticeContent3] = useState(
    state.noticeContent3 ? state.noticeContent3 : ""
  );

  const [noticeUrlTitle, setNoticeUrlTitle] = useState(
    state.noticeUrlTitle ? state.noticeUrlTitle : ""
  );

  const [noticeUrlTitle2, setNoticeUrlTitle2] = useState(
    state.noticeUrlTitle2 ? state.noticeUrlTitle2 : ""
  );

  const [noticeUrlTitle3, setNoticeUrlTitle3] = useState(
    state.noticeUrlTitle3 ? state.noticeUrlTitle3 : ""
  );

  const [noticeUrl, setNoticeUrl] = useState(
    state.noticeUrl ? state.noticeUrl : ""
  );

  const [noticeUrl2, setNoticeUrl2] = useState(
    state.noticeUrl2 ? state.noticeUrl2 : ""
  );

  const [noticeUrl3, setNoticeUrl3] = useState(
    state.noticeUrl3 ? state.noticeUrl3 : ""
  );

  const removeData = (parameter) => {
    switch (parameter) {
      case "noticeImg":
        setNoticeImg("");
        onSubmit({ _noticeImg: "" });
        break;

      case "noticeImg2":
        setNoticeImg2("");
        onSubmit({ _noticeImg2: "" });
        break;

      case "noticeImg3":
        setNoticeImg3("");
        onSubmit({ _noticeImg3: "" });
        break;

      default:
        break;
    }
  };

  const handleChange = (text, _parameter) => {
    switch (_parameter) {
      case "content1":
        setNoticeContent(text);
        getText(text, "content1");
        break;

      case "content2":
        setNoticeContent2(text);
        getText(text, "content2");
        break;

      case "content3":
        setNoticeContent3(text);
        getText(text, "content3");
        break;

      default:
        break;
    }
  };

  const getText = (text, _parameter) => {
    // console.log(data.text);
    // pushData(data.text);

    debounceGetText({
      _parameter: _parameter,
      text: text,
    });

    if (_parameter === "title1") {
      setNoticeTitle(text);
    } else if (_parameter === "noticeUrlTitle1") {
      setNoticeUrlTitle(text);
    } else if (_parameter === "noticeUrl1") {
      setNoticeUrl(text);
    } else if (_parameter === "title2") {
      setNoticeTitle2(text);
    } else if (_parameter === "noticeUrlTitle2") {
      setNoticeUrlTitle2(text);
    } else if (_parameter === "noticeUrl2") {
      setNoticeUrl2(text);
    } else if (_parameter === "title3") {
      setNoticeTitle3(text);
    } else if (_parameter === "noticeUrlTitle3") {
      setNoticeUrlTitle3(text);
    } else if (_parameter === "noticeUrl3") {
      setNoticeUrl3(text);
    }
  };

  const debounceGetText = useCallback(
    debounce(({ _parameter, text }) => {
      if (_parameter === "title1") {
        onSubmit({ _noticeTitle: text });
      } else if (_parameter === "content1") {
        onSubmit({ _noticeContent: text });
      } else if (_parameter === "noticeUrlTitle1") {
        onSubmit({ _noticeUrlTitle: text });
      } else if (_parameter === "noticeUrl1") {
        onSubmit({ _noticeUrl: text });
      } else if (_parameter === "realTitle") {
        onSubmit({ _realNoticeTitle: text });
      } else if (_parameter === "title2") {
        onSubmit({ _noticeTitle2: text });
      } else if (_parameter === "content2") {
        onSubmit({ _noticeContent2: text });
      } else if (_parameter === "noticeUrlTitle2") {
        onSubmit({ _noticeUrlTitle2: text });
      } else if (_parameter === "noticeUrl2") {
        onSubmit({ _noticeUrl2: text });
      } else if (_parameter === "title3") {
        onSubmit({ _noticeTitle3: text });
      } else if (_parameter === "content3") {
        onSubmit({ _noticeContent3: text });
      } else if (_parameter === "noticeUrlTitle3") {
        onSubmit({ _noticeUrlTitle3: text });
      } else if (_parameter === "noticeUrl3") {
        onSubmit({ _noticeUrl3: text });
      }
    }, 1500),
    []
  );

  const onSubmit = async ({
    _noticeTitle,
    _noticeContent,
    _noticeUrlTitle,
    _noticeUrl,
    _realNoticeTitle,
    _noticeTitle2,
    _noticeContent2,
    _noticeUrlTitle2,
    _noticeUrl2,
    _noticeTitle3,
    _noticeContent3,
    _noticeUrlTitle3,
    _noticeUrl3,
    _noticeImg,
    _noticeImg2,
    _noticeImg3,
  }) => {
    let content = {};

    if (_noticeTitle !== undefined) {
      content = {
        noticeTitle: _noticeTitle,
      };
      console.log(_noticeTitle);
    } else if (_noticeContent !== undefined) {
      content = {
        noticeContent: _noticeContent,
      };
      console.log(_noticeTitle);
    } else if (_noticeUrlTitle !== undefined) {
      content = {
        noticeUrlTitle: _noticeUrlTitle,
      };
    } else if (_noticeUrl !== undefined) {
      content = {
        noticeUrl: _noticeUrl,
      };
    } else if (_realNoticeTitle !== undefined) {
      content = {
        realNoticeTitle: _realNoticeTitle,
      };
    } else if (_noticeTitle2 !== undefined) {
      content = {
        noticeTitle2: _noticeTitle2,
      };
    } else if (_noticeContent2 !== undefined) {
      content = {
        noticeContent2: _noticeContent2,
      };
    } else if (_noticeUrlTitle2 !== undefined) {
      content = {
        noticeUrlTitle2: _noticeUrlTitle2,
      };
    } else if (_noticeUrl2 !== undefined) {
      content = {
        noticeUrl2: _noticeUrl2,
      };
    } else if (_noticeTitle3 !== undefined) {
      content = {
        noticeTitle3: _noticeTitle3,
      };
    } else if (_noticeContent3 !== undefined) {
      content = {
        noticeContent3: _noticeContent3,
      };
    } else if (_noticeUrlTitle3 !== undefined) {
      content = {
        noticeUrlTitle3: _noticeUrlTitle3,
      };
    } else if (_noticeUrl3 !== undefined) {
      content = {
        noticeUrl3: _noticeUrl3,
      };
    } else if (_noticeImg !== undefined) {
      content = {
        noticeImg: _noticeImg === "" ? "" : `${STORAGE_URL}/image${_noticeImg}`,
      };
    } else if (_noticeImg2 !== undefined) {
      content = {
        noticeImg2:
          _noticeImg2 === "" ? "" : `${STORAGE_URL}/image${_noticeImg2}`,
      };
    } else if (_noticeImg3 !== undefined) {
      content = {
        noticeImg3:
          _noticeImg3 === "" ? "" : `${STORAGE_URL}/image${_noticeImg3}`,
      };
    }

    await dbService
      .collection("newletters")
      .doc(letterId)
      .update(content)
      .then((res) => {
        pushData();
      });
  };

  const getBlurText = (text, _parameter) => {
    if (_parameter === "title1") {
      onSubmit({ _noticeTitle: text });
    } else if (_parameter === "content1") {
      onSubmit({ _noticeContent: text });
    } else if (_parameter === "noticeUrlTitle1") {
      onSubmit({ _noticeUrlTitle: text });
    } else if (_parameter === "noticeUrl1") {
      onSubmit({ _noticeUrl: text });
    } else if (_parameter === "realTitle") {
      onSubmit({ _realNoticeTitle: text });
    } else if (_parameter === "title2") {
      onSubmit({ _noticeTitle2: text });
    } else if (_parameter === "content2") {
      onSubmit({ _noticeContent2: text });
    } else if (_parameter === "noticeUrlTitle2") {
      onSubmit({ _noticeUrlTitle2: text });
    } else if (_parameter === "noticeUrl2") {
      onSubmit({ _noticeUrl2: text });
    } else if (_parameter === "title3") {
      onSubmit({ _noticeTitle3: text });
    } else if (_parameter === "content3") {
      onSubmit({ _noticeContent3: text });
    } else if (_parameter === "noticeUrlTitle3") {
      onSubmit({ _noticeUrlTitle3: text });
    } else if (_parameter === "noticeUrl3") {
      onSubmit({ _noticeUrl3: text });
    }
  };

  const pushData = () => {
    getData({ isSaved: true });
  };

  const uploadMainImg = async ({ _uploadMain, parameter }) => {
    let submitMainImg = "";
    console.log(_uploadMain);
    if (_uploadMain) {
      await ReactS3Client.uploadFile(_uploadMain, `${letterId}-${uuidv4()}`)
        .then((data) => {
          submitMainImg = data.location.split("image")[1];

          if (parameter === "noticeImg") {
            onSubmit({ _noticeImg: submitMainImg });
          } else if (parameter === "noticeImg2") {
            onSubmit({ _noticeImg2: submitMainImg });
          } else if (parameter === "noticeImg3") {
            onSubmit({ _noticeImg3: submitMainImg });
          }
        })
        .catch((err) => console.error(err));
    }
  };

  const onFileChange = (event, parameter) => {
    const {
      target: { files, name },
    } = event;

    const theFile = files[0];
    const reader = new FileReader();

    reader.onloadend = async (finishedEvent) => {
      // console.log(finishedEvent.target.result);
      const {
        currentTarget: { result },
      } = finishedEvent;

      try {
        new Compressor(theFile, {
          quality: 0.8,
          maxWidth: 500,
          maxHeight: 500,
          success(res) {
            if (parameter === "noticeImg") {
              setNoticeImg(result);
              uploadMainImg({ _uploadMain: res, parameter: parameter });
            } else if (parameter === "noticeImg2") {
              setNoticeImg2(result);
              uploadMainImg({ _uploadMain: res, parameter: parameter });
            } else if (parameter === "noticeImg3") {
              setNoticeImg3(result);
              uploadMainImg({ _uploadMain: res, parameter: parameter });
            }
          },
          error(err) {
            console.log(err.message);
          },
        });
      } catch (err) {
        console.log(err);
      }
    };
    theFile && reader.readAsDataURL(theFile);
  };

  return (
    <div>
      <div>
        <p
          style={{
            fontSize: "1.5rem",
            fontWeight: "bold",
          }}
        >
          공지사항
        </p>
        <p
          style={{
            marginTop: 5,
            fontSize: "0.9rem",
            color: "rgb(119, 119, 119)",
          }}
        >
          청첩장 내에 공지사항이 추가됩니다.
          <br />
          <span style={{ fontSize: "0.7rem" }}>
            (전세 버스 안내, 화환 거절 문구, 식사 안내 등<br />
            예식에 필요한 내용을 안내할 공지할 수 있습니다.)
          </span>
        </p>
      </div>

      <div>
        <SubTitleComponent title="공지사항 제목" />
        <TextInputComponent
          getText={(e) => {
            getText(e.text, "realTitle");
          }}
          _value={
            state.realNoticeTitle ? state.realNoticeTitle : "예식정보 및 안내"
          }
          getBlur={({ isBlur, text }) => {
            if (isBlur) {
              debounceGetText.cancel();
              getBlurText(text, "realTitle");
            }
          }}
        />
      </div>

      <div style={{ display: "flex", alignItems: "center", marginTop: 20 }}>
        <div
          style={{
            border: "1px solid #efefef",
            borderRadius: 8,
            padding: "8px 12px",
            cursor: "pointer",
            fontSize: 14,
            backgroundColor: noticeMenu == 1 ? "#192f0c" : "#fff",
            color: noticeMenu == 1 ? "#fff" : "#333",
          }}
          onClick={() => {
            setNoticeMenu(1);
          }}
        >
          첫번째 공지
        </div>

        <div
          style={{
            border: "1px solid #efefef",
            borderRadius: 8,
            padding: "8px 12px",
            cursor: "pointer",
            margin: "0px 8px",
            fontSize: 14,
            backgroundColor: noticeMenu == 2 ? "#192f0c" : "#fff",
            color: noticeMenu == 2 ? "#fff" : "#333",
          }}
          onClick={() => {
            setNoticeMenu(2);
          }}
        >
          두번째 공지
        </div>

        <div
          style={{
            border: "1px solid #efefef",
            borderRadius: 8,
            padding: "8px 12px",
            cursor: "pointer",
            fontSize: 14,
            backgroundColor: noticeMenu == 3 ? "#192f0c" : "#fff",
            color: noticeMenu == 3 ? "#fff" : "#333",
          }}
          onClick={() => {
            setNoticeMenu(3);
          }}
        >
          세번째 공지
        </div>
      </div>

      {/* 첫번째 공지 */}
      {noticeMenu == 1 && (
        <div style={{ marginTop: 5 }}>
          <div>
            <SubTitleComponent title="첫번째 공지사항" />
            <TextInputComponent
              getText={(e) => {
                getText(e.text, "title1");
              }}
              _value={noticeTitle}
              getBlur={({ isBlur, text }) => {
                if (isBlur) {
                  debounceGetText.cancel();
                  getBlurText(text, "title1");
                }
              }}
            />
          </div>
          <div style={{ marginTop: 16 }}>
            <ReactQuill
              theme={"snow"}
              onChange={(e) => {
                handleChange(e, "content1");
              }}
              value={noticeContent}
              modules={{
                toolbar: [
                  [
                    "bold",
                    "italic",
                    "underline",
                    // { list: "ordered" },
                    // { list: "bullet" },
                    // { align: ["center", "", "right"] },
                  ],
                ],
                clipboard: {
                  // toggle to add extra line breaks when pasting HTML:
                  matchVisual: false,
                },
              }}
              formats={[
                "bold",
                "italic",
                "underline",
                // "list",
                // "bullet",
                "align",
              ]}
              bounds={".app"}
              placeholder={"공지사항을 입력하세요"}
              onBlur={() => {
                debounceGetText.cancel();
                getBlurText(noticeContent, "content1");
              }}
            />
          </div>

          {/* 공지사항 사진 */}
          <div>
            <SubTitleComponent title="공지사항 사진" />
            <div
              style={{
                display: "flex",
                marginTop: 0,
                overflow: "auto",
              }}
            >
              <input
                id="noticeImg"
                name="noticeImg"
                type="file"
                accept="image/png, image/jpeg, image/jpg"
                onChange={(e) => {
                  console.log("hello");
                  onFileChange(e, "noticeImg");
                }}
                style={{
                  width: 1,
                  height: 1,
                  position: "absolute",
                  display: "none",
                }}
              />
              <div
                style={{
                  width: 140,
                  height: 140,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  border: "1px solid #e9e9e9",
                  borderRadius: 8,
                  cursor: "pointer",
                  position: "relative",
                  objectFit: "cover",
                  overflow: "hidden",
                }}
              >
                {noticeImg && (
                  <div>
                    <img
                      src={noticeImg}
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "contain",
                      }}
                    />
                    <div
                      style={{
                        backgroundColor: "rgb(0, 0, 0,0.6)",
                        height: 50,
                        width: "100%",
                        position: "absolute",
                        bottom: 0,
                        display: "flex",
                      }}
                    >
                      <label
                        for="noticeImg"
                        style={{
                          flex: 1,
                          cursor: "pointer",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <CreateIcon
                          style={{
                            color: "#fff",
                            fontSize: 22,
                          }}
                        />
                      </label>
                      <div
                        style={{
                          flex: 1,
                          cursor: "pointer",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                        onClick={() => {
                          removeData("noticeImg");
                        }}
                      >
                        <CloseIcon
                          style={{
                            color: "#fff",
                          }}
                        />
                      </div>
                    </div>
                  </div>
                )}

                {noticeImg == "" && (
                  <label for="noticeImg" style={{}}>
                    <span
                      style={{
                        width: 140,
                        height: 140,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        zIndex: 140,
                        backgroundColor: noticeImg
                          ? "rgba(0,0,0,0.3)"
                          : "#e9ecec",
                        borderRadius: 8,
                        color: noticeImg ? "#fff" : "#000",
                        flexDirection: "column",
                        position: "relative",
                        cursor: "pointer",
                      }}
                    >
                      <MdUpload
                        style={{
                          width: 25,
                          height: 25,
                          color: noticeImg ? "#fff" : "#666",
                        }}
                      />

                      <span
                        style={{
                          fontSize: 10,
                          color: noticeImg ? "#fff" : "#666",
                        }}
                      >
                        업로드
                      </span>
                    </span>
                  </label>
                )}
              </div>
            </div>
          </div>
          {/* 공지사항 사진 */}

          <div>
            <SubTitleComponent
              title="링크 버튼 타이틀"
              tooltip={
                <div>
                  (선택) 링크를 추가하시면 공지사항 하단에
                  <br />
                  링크로 이동가능한 버튼이 생성됩니다.
                </div>
              }
            />
            <TextInputComponent
              getText={(e) => {
                getText(e.text, "noticeUrlTitle1");
              }}
              _value={noticeUrlTitle}
              placeholder="자세히 보기"
              getBlur={({ isBlur, text }) => {
                if (isBlur) {
                  debounceGetText.cancel();
                  getBlurText(text, "noticeUrlTitle1");
                }
              }}
            />
          </div>

          <div>
            <SubTitleComponent title="링크 URL" />
            <TextInputComponent
              getText={(e) => {
                getText(e.text, "noticeUrl1");
              }}
              _value={noticeUrl}
              placeholder="https://www.youtube.com"
              getBlur={({ isBlur, text }) => {
                if (isBlur) {
                  debounceGetText.cancel();
                  getBlurText(text, "noticeUrl1");
                }
              }}
            />
          </div>
        </div>
      )}
      {/* 첫번째 공지 */}

      {/* 두번째 공지 */}
      {noticeMenu == 2 && (
        <div style={{ marginTop: 5 }}>
          <div>
            <SubTitleComponent title="두번째 공지사항" />
            <TextInputComponent
              getText={(e) => {
                getText(e.text, "title2");
              }}
              _value={noticeTitle2}
              getBlur={({ isBlur, text }) => {
                if (isBlur) {
                  debounceGetText.cancel();
                  getBlurText(text, "title2");
                }
              }}
            />
          </div>
          <div style={{ marginTop: 16 }}>
            <ReactQuill
              theme={"snow"}
              onChange={(e) => {
                handleChange(e, "content2");
              }}
              value={noticeContent2}
              modules={{
                toolbar: [["bold", "italic", "underline"]],
                clipboard: {
                  matchVisual: false,
                },
              }}
              formats={["bold", "italic", "underline", "align"]}
              bounds={".app"}
              placeholder={"공지사항을 입력하세요"}
              onBlur={() => {
                debounceGetText.cancel();
                getBlurText(noticeContent2, "content2");
              }}
            />
          </div>

          {/* 공지사항 사진 */}
          <div>
            <SubTitleComponent title="공지사항 사진" />
            <div
              style={{
                display: "flex",
                marginTop: 0,
                overflow: "auto",
              }}
            >
              <input
                id="noticeImg2"
                name="noticeImg2"
                type="file"
                accept="image/png, image/jpeg, image/jpg"
                onChange={(e) => {
                  console.log("hello");
                  onFileChange(e, "noticeImg2");
                }}
                style={{
                  width: 1,
                  height: 1,
                  position: "absolute",
                  display: "none",
                }}
              />
              <div
                style={{
                  width: 140,
                  height: 140,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  border: "1px solid #e9e9e9",
                  borderRadius: 8,
                  cursor: "pointer",
                  position: "relative",
                  objectFit: "cover",
                  overflow: "hidden",
                }}
              >
                {noticeImg2 && (
                  <div>
                    <img
                      src={noticeImg2}
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "contain",
                      }}
                    />
                    <div
                      style={{
                        backgroundColor: "rgb(0, 0, 0,0.6)",
                        height: 50,
                        width: "100%",
                        position: "absolute",
                        bottom: 0,
                        display: "flex",
                      }}
                    >
                      <label
                        for="noticeImg2"
                        style={{
                          flex: 1,
                          cursor: "pointer",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <CreateIcon
                          style={{
                            color: "#fff",
                            fontSize: 22,
                          }}
                        />
                      </label>
                      <div
                        style={{
                          flex: 1,
                          cursor: "pointer",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                        onClick={() => {
                          removeData("noticeImg2");
                        }}
                      >
                        <CloseIcon
                          style={{
                            color: "#fff",
                          }}
                        />
                      </div>
                    </div>
                  </div>
                )}

                {noticeImg2 == "" && (
                  <label for="noticeImg2" style={{}}>
                    <span
                      style={{
                        width: 140,
                        height: 140,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        zIndex: 140,
                        backgroundColor: noticeImg2
                          ? "rgba(0,0,0,0.3)"
                          : "#e9ecec",
                        borderRadius: 8,
                        color: noticeImg2 ? "#fff" : "#000",
                        flexDirection: "column",
                        position: "relative",
                        cursor: "pointer",
                      }}
                    >
                      <MdUpload
                        style={{
                          width: 25,
                          height: 25,
                          color: noticeImg2 ? "#fff" : "#666",
                        }}
                      />

                      <span
                        style={{
                          fontSize: 10,
                          color: noticeImg2 ? "#fff" : "#666",
                        }}
                      >
                        업로드
                      </span>
                    </span>
                  </label>
                )}
              </div>
            </div>
          </div>
          {/* 공지사항 사진 */}

          <div>
            <SubTitleComponent
              title="링크 버튼 타이틀"
              tooltip={
                <div>
                  (선택) 링크를 추가하시면 공지사항 하단에
                  <br />
                  링크로 이동가능한 버튼이 생성됩니다.
                </div>
              }
            />
            <TextInputComponent
              getText={(e) => {
                getText(e.text, "noticeUrlTitle2");
              }}
              _value={noticeUrlTitle2}
              placeholder="자세히 보기"
              getBlur={({ isBlur, text }) => {
                if (isBlur) {
                  debounceGetText.cancel();
                  getBlurText(text, "noticeUrlTitle2");
                }
              }}
            />
          </div>

          <div>
            <SubTitleComponent title="링크 URL" />
            <TextInputComponent
              getText={(e) => {
                getText(e.text, "noticeUrl2");
              }}
              _value={noticeUrl2}
              placeholder="https://www.youtube.com"
              getBlur={({ isBlur, text }) => {
                if (isBlur) {
                  debounceGetText.cancel();
                  getBlurText(text, "noticeUrl2");
                }
              }}
            />
          </div>
        </div>
      )}
      {/* 두번째 공지 */}

      {/* 세번째 공지 */}
      {noticeMenu == 3 && (
        <div style={{ marginTop: 5 }}>
          <div>
            <SubTitleComponent title="세번째 공지사항" />
            <TextInputComponent
              getText={(e) => {
                getText(e.text, "title3");
              }}
              _value={noticeTitle3}
              getBlur={({ isBlur, text }) => {
                if (isBlur) {
                  debounceGetText.cancel();
                  getBlurText(text, "title3");
                }
              }}
            />
          </div>
          <div style={{ marginTop: 16 }}>
            <ReactQuill
              theme={"snow"}
              onChange={(e) => {
                handleChange(e, "content3");
              }}
              value={noticeContent3}
              modules={{
                toolbar: [["bold", "italic", "underline"]],
                clipboard: {
                  matchVisual: false,
                },
              }}
              formats={["bold", "italic", "underline", "align"]}
              bounds={".app"}
              placeholder={"공지사항을 입력하세요"}
              onBlur={() => {
                debounceGetText.cancel();
                getBlurText(noticeContent3, "content3");
              }}
            />
          </div>

          {/* 공지사항 사진 */}
          <div>
            <SubTitleComponent title="공지사항 사진" />
            <div
              style={{
                display: "flex",
                marginTop: 0,
                overflow: "auto",
              }}
            >
              <input
                id="noticeImg3"
                name="noticeImg3"
                type="file"
                accept="image/png, image/jpeg, image/jpg"
                onChange={(e) => {
                  console.log("hello");
                  onFileChange(e, "noticeImg3");
                }}
                style={{
                  width: 1,
                  height: 1,
                  position: "absolute",
                  display: "none",
                }}
              />
              <div
                style={{
                  width: 140,
                  height: 140,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  border: "1px solid #e9e9e9",
                  borderRadius: 8,
                  cursor: "pointer",
                  position: "relative",
                  objectFit: "cover",
                  overflow: "hidden",
                }}
              >
                {noticeImg3 && (
                  <div>
                    <img
                      src={noticeImg3}
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "contain",
                      }}
                    />
                    <div
                      style={{
                        backgroundColor: "rgb(0, 0, 0,0.6)",
                        height: 50,
                        width: "100%",
                        position: "absolute",
                        bottom: 0,
                        display: "flex",
                      }}
                    >
                      <label
                        for="noticeImg3"
                        style={{
                          flex: 1,
                          cursor: "pointer",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <CreateIcon
                          style={{
                            color: "#fff",
                            fontSize: 22,
                          }}
                        />
                      </label>
                      <div
                        style={{
                          flex: 1,
                          cursor: "pointer",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                        onClick={() => {
                          removeData("noticeImg3");
                        }}
                      >
                        <CloseIcon
                          style={{
                            color: "#fff",
                          }}
                        />
                      </div>
                    </div>
                  </div>
                )}

                {noticeImg3 == "" && (
                  <label for="noticeImg3" style={{}}>
                    <span
                      style={{
                        width: 140,
                        height: 140,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        zIndex: 140,
                        backgroundColor: noticeImg3
                          ? "rgba(0,0,0,0.3)"
                          : "#e9ecec",
                        borderRadius: 8,
                        color: noticeImg3 ? "#fff" : "#000",
                        flexDirection: "column",
                        position: "relative",
                        cursor: "pointer",
                      }}
                    >
                      <MdUpload
                        style={{
                          width: 25,
                          height: 25,
                          color: noticeImg3 ? "#fff" : "#666",
                        }}
                      />

                      <span
                        style={{
                          fontSize: 10,
                          color: noticeImg3 ? "#fff" : "#666",
                        }}
                      >
                        업로드
                      </span>
                    </span>
                  </label>
                )}
              </div>
            </div>
          </div>
          {/* 공지사항 사진 */}

          <div>
            <SubTitleComponent
              title="링크 버튼 타이틀"
              tooltip={
                <div>
                  (선택) 링크를 추가하시면 공지사항 하단에
                  <br />
                  링크로 이동가능한 버튼이 생성됩니다.
                </div>
              }
            />
            <TextInputComponent
              getText={(e) => {
                getText(e.text, "noticeUrlTitle3");
              }}
              _value={noticeUrlTitle3}
              placeholder="자세히 보기"
              getBlur={({ isBlur, text }) => {
                if (isBlur) {
                  debounceGetText.cancel();
                  getBlurText(text, "noticeUrlTitle3");
                }
              }}
            />
          </div>

          <div>
            <SubTitleComponent title="링크 URL" />
            <TextInputComponent
              getText={(e) => {
                getText(e.text, "noticeUrl3");
              }}
              _value={noticeUrl3}
              placeholder="https://www.youtube.com"
              getBlur={({ isBlur, text }) => {
                if (isBlur) {
                  debounceGetText.cancel();
                  getBlurText(text, "noticeUrl3");
                }
              }}
            />
          </div>
        </div>
      )}
      {/* 세번째 공지 */}
    </div>
  );
}
