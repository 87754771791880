import React, { forwardRef, useState, useCallback, useEffect } from "react";
import Switch from "react-switch";
import Compressor from "compressorjs";
import CloseIcon from "@material-ui/icons/Close";
import CreateIcon from "@material-ui/icons/Create";
import SubTitleComponent from "../SubTitleComponent/SubTitleComponent";
import TextInputComponent from "../TextInputComponent/TextInputComponent";
import DatePicker from "react-datepicker";
import { dbService } from "../../fbase";

import "react-datepicker/dist/react-datepicker.css";

import { ko } from "date-fns/esm/locale";
import CheckboxComponent from "../CheckboxComponent/CheckboxComponent";
import { debounce } from "lodash";

import S3 from "react-aws-s3";
import { v4 as uuidv4 } from "uuid";

const STORAGE_URL = "http://storage.cryucard.com";

export default function MainPicComponent({ state, getData, letterId }) {
    const [mainImg, setMainImg] = useState(state.mainImg ? state.mainImg : "");
    const [uploadMain, setUploadMain] = useState("");
    const [weddingDate, setWeddingDate] = useState(
        state.weddingDate ? new Date(state.weddingDate) : new Date()
    );
    const [weddingTime_1, setWeddingTime_1] = useState(
        state.weddingTime
            ? state.weddingTime.slice(0, 2) * 1 - 12 >= 0
                ? "pm"
                : "am"
            : "pm"
    ); // state.weddingTime 14:00:00
    const [weddingTime_2, setWeddingTime_2] = useState(
        state.weddingTime
            ? state.weddingTime.slice(0, 2) * 1 - 12 > 0
                ? state.weddingTime.slice(0, 2) * 1 - 12
                : state.weddingTime.slice(0, 2) * 1
            : "1"
    );
    const [weddingTime_3, setWeddingTime_3] = useState(
        state.weddingTime ? state.weddingTime.slice(3, 5) : "00"
    );
    const [effect, setEffect] = useState(state.effect ? state.effect : "");

    const [isCalendar, setIsCalendar] = useState(
        state.isCalendar ? true : state.isCalendar === undefined ? true : false
    );

    const [isDday, setIsDday] = useState(
        state.isDday ? true : state.isDday === undefined ? true : false
    );

    const minDate = new Date();
    const maxDate = minDate.setDate(minDate.getDate() + 210);

    // s3설정
    const config = {
        bucketName: "new-cryucard",
        dirName: `image/${state.weddingDate}`,
        region: "ap-northeast-2",
        accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
        secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
    };

    const ReactS3Client = new S3(config);
    // s3설정

    const uploadMainImg = async ({ _uploadMain }) => {
        let submitMainImg = "";
        // console.log(_uploadMain);
        if (_uploadMain) {
            await ReactS3Client.uploadFile(
                _uploadMain,
                `${letterId}-${uuidv4()}`
            )
                .then((data) => {
                    submitMainImg = data.location.split("image")[1];
                    onSubmit({ _mainImg: submitMainImg });
                })
                .catch((err) => console.error(err));
        }
    };

    const onFileChange = (event, index) => {
        const {
            target: { files, name },
        } = event;

        const theFile = files[0];
        const reader = new FileReader();

        reader.onloadend = async (finishedEvent) => {
            // console.log(finishedEvent.target.result);
            const {
                currentTarget: { result },
            } = finishedEvent;

            try {
                new Compressor(theFile, {
                    quality: 0.8,
                    maxWidth: 2000,
                    maxHeight: 2000,
                    success(res) {
                        setMainImg(result);
                        // setUploadMain(res);

                        uploadMainImg({ _uploadMain: res });
                    },
                    error(err) {
                        console.log(err.message);
                    },
                });
            } catch (err) {
                console.log(err);
            }
        };
        theFile && reader.readAsDataURL(theFile);
    };

    const ImgPreviewComponent = () => {
        return (
            <div>
                <SubTitleComponent title="메인사진" />
                <div
                    style={{
                        width: 200,
                        height: 200,
                        border: "1px solid #e9e9e9",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        borderRadius: 8,
                        overflow: "hidden",
                    }}
                >
                    <div
                        style={{
                            backgroundColor: "#fefcfc",
                            width: "100%",
                            height: "100%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            position: "relative",
                        }}
                    >
                        <img
                            src={mainImg}
                            style={{
                                maxHeight: 200,
                                maxWidth: 200,
                            }}
                        />
                        <div
                            style={{
                                backgroundColor: "rgb(0, 0, 0,0.6)",
                                height: 50,
                                width: 200,
                                position: "absolute",
                                bottom: 0,
                                display: "flex",
                            }}
                        >
                            <label
                                for="mainImg"
                                style={{
                                    flex: 1,
                                    cursor: "pointer",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                }}
                            >
                                <CreateIcon
                                    style={{
                                        color: "#fff",
                                        fontSize: 22,
                                    }}
                                />
                            </label>
                            <div
                                style={{
                                    flex: 1,
                                    cursor: "pointer",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                }}
                                onClick={() => {
                                    setMainImg("");
                                    onSubmit({ _mainImg: "" });
                                }}
                            >
                                <CloseIcon
                                    style={{
                                        color: "#fff",
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <EffectSelectOption />
            </div>
        );
    };

    const ImgUploadComponent = () => {
        return (
            <label for="mainImg">
                <div
                    style={{
                        height: 200,
                        border: "1px dashed #888",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        color: "#888",
                        cursor: "pointer",
                        fontSize: "0.9rem",
                        marginTop: 30,
                    }}
                >
                    이 곳을 통해 메인사진을 업로드해주세요
                </div>
            </label>
        );
    };

    const EffectSelectOption = () => {
        return (
            <div>
                <SubTitleComponent title="메인사진 효과" />
                <div className="prod-select-container">
                    <select
                        name="effect"
                        id="effect-select"
                        className="prod-select"
                        onChange={(e) => {
                            setEffect(e.target.value);
                            debounceSubmit({ _effect: e.target.value });
                        }}
                        value={effect}
                    >
                        <option value="no">없음</option>
                        <option value="confetti">컨페티</option>
                        <option value="daisy">데이지</option>
                        <option value="star">별</option>
                        <option value="sakura">벚꽃</option>
                        <option value="wave">물결</option>
                        <option value="snow">눈</option>
                    </select>
                </div>
            </div>
        );
    };

    const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
        <div
            onClick={onClick}
            style={{
                width: 216,
                height: 40,
                border: "1px solid #e9e9e9",
                borderRadius: 8,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",

                fontSize: "0.9rem",
                lineHeight: "0.9rem",
                color: "#333",

                cursor: "pointer",
            }}
        >
            {/* 2022년 05월 10일 토요일 */}
            {value}
        </div>
    ));

    const pushData = () => {
        getData({ isSaved: true });
    };

    const debounceWeddingTime = useCallback(
        debounce(async (_weddingTime_1, _weddingTime_2, _weddingTime_3) => {
            // console.log("debounce" + _weddingTime_1);

            let calTime = _weddingTime_1 === "am" ? 0 : 12;
            let calTime2 = _weddingTime_2 * 1;

            let calTime3 =
                calTime + calTime2 < 10
                    ? "0" + (calTime + calTime2)
                    : calTime + calTime2 === 24
                    ? calTime + calTime2 - 12
                    : calTime + calTime2;

            // console.log(`${_weddingTime_1}${_weddingTime_2}${_weddingTime_3}`);

            let _weddingTime = `${calTime3}:${_weddingTime_3}:00`;
            // console.log(_weddingTime);
            onSubmit({ _weddingTime });
        }, 1500),
        []
    );

    const debounceSubmit = useCallback(
        debounce(
            async ({
                _weddingDate,
                _weddingTime,
                _isCalendar,
                _isDday,
                _mainImg,
                _effect,
            }) => {
                let content = {};

                if (_weddingDate) {
                    content = {
                        weddingDate: _weddingDate,
                    };
                    console.log("_weddingDate");
                } else if (_weddingTime) {
                    content = {
                        weddingTime: _weddingTime,
                    };
                    console.log("_weddingTime");
                } else if (_isCalendar === true || _isCalendar === false) {
                    content = {
                        isCalendar: _isCalendar,
                    };
                    console.log("_isCalendar");
                } else if (_isDday === true || _isDday === false) {
                    content = {
                        isDday: _isDday,
                    };
                    console.log(_isDday);
                } else if (_effect) {
                    content = {
                        effect: _effect,
                    };
                }

                await dbService
                    .collection("newletters")
                    .doc(letterId)
                    .update(content)
                    .then((res) => {
                        pushData();
                    });
            },
            1500
        ),
        []
    );

    const onSubmit = async ({
        _weddingDate,
        _weddingTime,
        _isCalendar,
        _isDday,
        _mainImg,
        _effect,
    }) => {
        let content = {};

        if (_weddingDate) {
            content = {
                weddingDate: _weddingDate,
            };
            console.log("_weddingDate");
        } else if (_weddingTime) {
            content = {
                weddingTime: _weddingTime,
            };
            console.log("_weddingTime");
        } else if (_isCalendar === true || _isCalendar === false) {
            content = {
                isCalendar: _isCalendar,
            };
            console.log("_isCalendar");
        } else if (_isDday === true || _isDday === false) {
            content = {
                isDday: _isDday,
            };
            console.log(_isDday);
        } else if (_effect) {
            content = {
                effect: _effect,
            };
            console.log(_isDday);
        } else if (_mainImg !== undefined) {
            content = {
                mainImg:
                    _mainImg === ""
                        ? _mainImg
                        : `${STORAGE_URL}/image${_mainImg}`,
            };
        }

        await dbService
            .collection("newletters")
            .doc(letterId)
            .update(content)
            .then((res) => {
                pushData();
            });
    };

    const WeddingDateComponent = () => {
        return (
            <div>
                {/* 예식일자 */}
                <div>
                    <SubTitleComponent title="예식일자" />
                    <div>
                        <DatePicker
                            selected={weddingDate}
                            onChange={(weddingDate) => {
                                setWeddingDate(weddingDate);
                                // console.log(weddingDate);
                                onSubmit({
                                    _weddingDate: `${weddingDate.getFullYear()}-${
                                        weddingDate.getMonth() + 1 < 10
                                            ? "0" +
                                              (
                                                  weddingDate.getMonth() + 1
                                              ).toString()
                                            : weddingDate.getMonth() + 1
                                    }-${
                                        weddingDate.getDate() < 10
                                            ? "0" +
                                              weddingDate.getDate().toString()
                                            : weddingDate.getDate()
                                    }`,
                                });
                            }}
                            customInput={<ExampleCustomInput />}
                            locale={ko}
                            dateFormat="yyyy년 MM월 dd일 E요일"
                            minDate={new Date()}
                            maxDate={maxDate}
                        />
                    </div>
                </div>
                {/* 예식일자 */}

                {/* 예식시간 */}
                <div style={{ width: 300 }}>
                    <SubTitleComponent title="예식시간" />
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "flex-start",
                            alignItems: "center",
                            color: "#333",
                        }}
                    >
                        <div style={{ flex: 0.75 }}>
                            <div
                                className="prod-select-container"
                                style={{ width: "auto" }}
                            >
                                <select
                                    name="time"
                                    id="time-select"
                                    className="prod-select"
                                    onChange={(e) => {
                                        setWeddingTime_1(e.target.value);
                                        // console.log(e.target.value);
                                        debounceWeddingTime(
                                            e.target.value,
                                            weddingTime_2,
                                            weddingTime_3
                                        );
                                    }}
                                    value={weddingTime_1}
                                >
                                    <option value="am">오전</option>
                                    <option value="pm">오후</option>
                                </select>
                            </div>
                        </div>

                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "flex-start",
                                marginLeft: 16,
                            }}
                        >
                            {/* <div style={{ maxWidth: 60 }}>
                                <TextInputComponent />
                            </div> */}

                            <div style={{ maxWidth: 80 }}>
                                <div
                                    className="prod-select-container"
                                    style={{ width: "auto" }}
                                >
                                    <select
                                        name="time"
                                        id="time-select"
                                        className="prod-select"
                                        onChange={(e) => {
                                            setWeddingTime_2(e.target.value);
                                            debounceWeddingTime(
                                                weddingTime_1,
                                                e.target.value,
                                                weddingTime_3
                                            );
                                        }}
                                        value={weddingTime_2}
                                    >
                                        <option value="1">1</option>
                                        <option value="2">2</option>
                                        <option value="3">3</option>
                                        <option value="4">4</option>
                                        <option value="5">5</option>
                                        <option value="6">6</option>
                                        <option value="7">7</option>
                                        <option value="8">8</option>
                                        <option value="9">9</option>
                                        <option value="10">10</option>
                                        <option value="11">11</option>
                                        <option value="12">12</option>
                                    </select>
                                </div>
                            </div>
                            <span style={{ marginLeft: 5 }}>시</span>
                        </div>

                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "flex-start",
                                marginLeft: 10,
                            }}
                        >
                            <div
                                className="prod-select-container"
                                style={{ width: "auto" }}
                            >
                                <select
                                    name="time"
                                    id="time-select"
                                    className="prod-select"
                                    onChange={(e) => {
                                        setWeddingTime_3(e.target.value);
                                        debounceWeddingTime(
                                            weddingTime_1,
                                            weddingTime_2,
                                            e.target.value
                                        );
                                    }}
                                    value={weddingTime_3}
                                >
                                    <option value="00">00</option>
                                    <option value="00">00</option>
                                    <option value="10">10</option>
                                    <option value="15">15</option>
                                    <option value="20">20</option>
                                    <option value="25">25</option>
                                    <option value="30">30</option>
                                    <option value="35">35</option>
                                    <option value="40">40</option>
                                    <option value="45">45</option>
                                    <option value="50">50</option>
                                    <option value="55">55</option>
                                </select>
                            </div>
                            <span style={{ marginLeft: 5 }}>분</span>
                        </div>
                    </div>

                    <div style={{ display: "flex", marginTop: 10 }}>
                        <div
                            onClick={() => {
                                setIsCalendar((value) => {
                                    onSubmit({ _isCalendar: !value });

                                    return !value;
                                });
                            }}
                        >
                            <CheckboxComponent
                                title="달력 표시"
                                isChecked={isCalendar}
                            />
                        </div>
                        <div style={{ marginLeft: 10 }} />
                        <div
                            onClick={() => {
                                setIsDday((value) => {
                                    onSubmit({ _isDday: !value });

                                    return !value;
                                });
                                // console.log(isDday);
                            }}
                        >
                            <CheckboxComponent
                                title="디데이 표시"
                                isChecked={isDday}
                            />
                        </div>
                    </div>
                </div>
                {/* 예식시간 */}
            </div>
        );
    };
    // useEffect(() => {
    //     console.log(
    //         state.weddingTime
    //             ? state.weddingTime.slice(0, 2) * 1 - 12 > 0
    //                 ? state.weddingTime.slice(0, 2) * 1 - 12
    //                 : state.weddingTime.slice(0, 2) * 1
    //             : "01"
    //     );
    // }, []);

    return (
        <div>
            <WeddingDateComponent />
            <div>
                <input
                    id="mainImg"
                    name="mainImg"
                    type="file"
                    accept="image/png, image/jpeg, image/jpg"
                    onChange={onFileChange}
                    style={{
                        width: 1,
                        height: 1,
                        position: "absolute",
                        display: "none",
                    }}
                />
                {!mainImg ? <ImgUploadComponent /> : <ImgPreviewComponent />}
            </div>
        </div>
    );
}
